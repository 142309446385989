<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input v-model="filters.name" placeholder="设备号，设备名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="getDevices">
            <i class="el-icon-search"></i>
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="roletype>3" type="primary" @click="handleAdd">
            <i class="el-icon-plus"></i>
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table
      :data="deviceslist"
      v-loading="listLoading"
      style="width: 100%;"
      stripe
      highlight-current-row
    >
      <el-table-column prop="day" label="服务状态" min-width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.day<0" type="danger">已过期</el-tag>
          <el-tag v-else-if="scope.row.day<30" type="warning">即将到期</el-tag>
          <el-tag v-else type="success">正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="devicecode" label="设备号" min-width="150" sortable></el-table-column>
      <el-table-column prop="devicename" label="设备名称" min-width="160" sortable></el-table-column>
      <el-table-column prop="sn" label="SN" min-width="160" sortable></el-table-column>
      <el-table-column prop="sim" label="物联网卡ICCID" min-width="200" sortable>
        <template slot-scope="scope">
          <span>{{scope.row.sim==null?'':scope.row.sim}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hardversion" label="硬件版本号" min-width="200" sortable>
        <template slot-scope="scope">
          <span>{{scope.row.hardversion==null?'':scope.row.hardversion}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="devicetype"
        label="设备种类"
        width="170"
        :formatter="formatDeviceType"
        sortable
      ></el-table-column>
      <el-table-column
        prop="devicekind"
        label="设备类型"
        width="150"
        :formatter="formatDeviceKind"
        sortable
      ></el-table-column>
      <el-table-column
        prop="createdat"
        label="安装时间"
        width="180"
        :formatter="formatCreatedAt"
        sortable
      ></el-table-column>
      <el-table-column
        prop="expiredat"
        label="到期时间"
        width="180"
        :formatter="formatExpiredAt"
        sortable
      ></el-table-column>

      <el-table-column prop="customername" label="所属客户" min-width="180" sortable></el-table-column>
      <el-table-column label="操作" fixed="right" width="150" min-width="150">
        <template slot-scope="scope">
          <el-button
            :disabled="roletype>3"
            size="small"
            @click="handleEdit(scope.$index, scope.row)"
          >
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button
            :disabled="roletype>3"
            type="warning"
            size="small"
            @click="handleDel(scope.$index, scope.row)"
          >
            <i class="el-icon-delete"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        layout="prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :page-size="20"
        :total="total"
        style="float:right;"
      ></el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog title="编辑" :visible.sync="editFormVisible" :close-on-click-modal="false">
      <el-form :model="editForm" label-width="100px" :rules="editFormRules" ref="editForm">
        <el-form-item label="设备号" prop="devicecode">
          <el-input v-model="editForm.devicecode" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="devicename">
          <el-input v-model="editForm.devicename" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="Serial No." prop="sn">
          <el-input v-model="editForm.sn">
            <el-tooltip placement="bottom" effect="light" slot="append">
              <div slot="content">
                设备唯一标识
                <br />可用于绑定微信报警功能
              </div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-input>
        </el-form-item>
        <el-form-item label="物联网卡号" prop="sim">
          <el-input v-model="editForm.sim">
            <el-tooltip placement="bottom" effect="light" slot="append">
              <div slot="content">{{simContent}}</div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-input>
        </el-form-item>

        <el-form-item label="设备种类" prop="devicetype">
          <el-select
            v-model="editForm.devicetype"
            placeholder="请选择设备种类"
            disabled
            no-data-text="对不起，您没有权限"
          >
            <el-option
              v-for="item in devicetypeModels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型" prop="devicekind">
          <el-select
            v-model="editForm.devicekind"
            placeholder="请选择设备类型"
            v-if="editForm.devicetype != 5"
          >
            <el-option
              v-for="item in devicekindModels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属客户" prop="customerid">
          <customer-select :selected="editForm.customerid" @changeVal="toggleEditChange"></customer-select>
        </el-form-item>
        <el-form-item label="到期时间" prop="expiredat">
          <el-date-picker
            v-model="editForm.expiredat"
            align="right"
            type="date"
            placeholder="选择到期时间"
            :picker-options="pickerOptions"
            :disabled="true"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
      </div>
    </el-dialog>

    <!--新增界面-->
    <el-dialog title="新增" :visible.sync="addFormVisible" :close-on-click-modal="false">
      <el-form :model="addForm" label-width="100px" :rules="addFormRules" ref="addForm">
        <el-form-item label="设备号" prop="devicecode">
          <el-input v-model="addForm.devicecode" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="devicename">
          <el-input v-model="addForm.devicename" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="Serial No." prop="sn">
          <el-input v-model="addForm.sn">
            <el-tooltip placement="bottom" effect="light" slot="append">
              <div slot="content">
                设备唯一标识
                <br />可用于绑定微信报警功能
              </div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-input>
        </el-form-item>
        <el-form-item label="物联网卡号">
          <el-input v-model="addForm.sim"></el-input>
        </el-form-item>

        <el-form-item label="设备种类" prop="devicetype">
          <el-select
            v-model="addForm.devicetype"
            placeholder="请选择设备种类"
            no-data-text="对不起，您没有权限"
            @change="addFormDeviceTypeSelected"
          >
            <el-option
              v-for="item in devicetypeModels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型" prop="devicekind">
          <el-select
            v-model="addForm.devicekind"
            placeholder="请选择设备类型"
            :disabled="addForm.isWarehouseAlarm"
          >
            <el-option
              v-for="item in devicekindModels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属客户" prop="customerid">
          <customer-select :selected="addForm.customerid" @changeVal="toggleAddChange"></customer-select>
        </el-form-item>
        <el-form-item label="到期时间" prop="expiredat">
          <el-date-picker
            v-model="addForm.expiredat"
            align="right"
            type="date"
            placeholder="选择到期时间"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import NProgress from "nprogress";
import twix from "twix";
import moment from "moment";
import { mapGetters } from "vuex";
import CustomerSelect from "../components/CustomerSelect";
export default {
  components: {
    CustomerSelect
  },
  data() {
    var validateDeviceKind = (rule, value, callback) => {
      if (this.addForm.devicetype == 5) {
        return callback();
      }
      if (value == null || Number.isNaN(Number.parseInt(value))) {
        return callback(new Error("请选择设备类型"));
      } else {
        return callback();
      }
    };
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
        shortcuts: [
          {
            text: "一年",
            onClick(picker) {
              picker.$emit(
                "pick",
                new Date(
                  moment()
                    .add(1, "years")
                    .subtract(new Date().getDate(), "days")
                )
              );
            }
          },
          {
            text: "两年",
            onClick(picker) {
              picker.$emit(
                "pick",
                new Date(
                  moment()
                    .add(2, "years")
                    .subtract(new Date().getDate(), "days")
                )
              );
            }
          },
          {
            text: "三年",
            onClick(picker) {
              picker.$emit(
                "pick",
                new Date(
                  moment()
                    .add(3, "years")
                    .subtract(new Date().getDate(), "days")
                )
              );
            }
          }
        ]
      },
      filters: {
        name: ""
      },
      devicekindModels: [
        {
          label: "温湿度变送器",
          value: 0
        },
        {
          label: "温度变送器",
          value: 1
        }
      ],
      deviceslist: [],
      total: 0,
      page: 1,
      listLoading: false,

      editFormVisible: false, //编辑界面是否显示
      editLoading: false,
      editFormRules: {
        devicecode: [
          {
            required: true,
            message: "请输入设备号",
            trigger: "blur"
          }
        ],
        devicename: [
          {
            required: true,
            message: "请输入设备名称",
            trigger: "blur"
          }
        ],
        devicetype: [
          {
            required: true,
            type: "number",
            message: "请选择设备种类",
            trigger: "blur"
          }
        ],
        devicekind: [
          {
            // required: true,
            // type: "number",
            // message: "请选择设备类型",
            validator: validateDeviceKind,
            trigger: "blur"
          }
        ],
        customerid: [
          {
            required: true,
            type: "number",
            message: "请选择所属客户",
            trigger: "blur"
          }
        ],
        expiredat: [
          {
            required: true,
            type: "date",
            message: "请选择到期时间",
            trigger: "blur"
          }
        ]
      },
      //编辑界面数据
      editForm: {
        deviceid: "",
        devicecode: "",
        sn: "",
        sim: "",
        devicename: "",
        devicekind: null,
        devicetype: null,
        customerid: null,
        expiredat: null
      },

      addFormVisible: false, //新增界面是否显示
      addLoading: false,
      addFormRules: {
        devicecode: [
          {
            required: true,
            message: "请输入设备号",
            trigger: "blur"
          }
        ],
        devicename: [
          {
            required: true,
            message: "请输入设备名称",
            trigger: "blur"
          }
        ],
        sim: [
          {
            required: false,
            type: "number",
            message: "请输入纯数字的ICCID号",
            trigger: "blur"
          }
        ],
        devicetype: [
          {
            required: true,
            type: "number",
            message: "请选择设备种类",
            trigger: "blur"
          }
        ],
        devicekind: [
          {
            // required: true,
            // type: "number",
            // message: "请选择设备类型",
            validator: validateDeviceKind,
            trigger: "blur"
          }
        ],
        customerid: [
          {
            required: true,
            type: "number",
            message: "请选择所属客户",
            trigger: "blur"
          }
        ],
        expiredat: [
          {
            required: true,
            type: "date",
            message: "请选择到期时间",
            trigger: "blur"
          }
        ]
      },
      //新增界面数据
      addForm: {
        devicecode: "",
        devicename: "",
        sn: "",
        sim: "",
        devicetype: null,
        devicekind: null,
        customerid: null,
        expiredat: null,
        isWarehouseAlarm: false //是否为库房综合报警器报警器，默认为false
      },
      editeddevicecode: "",
      simContent: ""
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"]),
    devicetypeModels: function() {
      // 0:超管，1:保留，2:代理商超管，3:保留，4:客户管理员，5:客户普通用户
      if (this.roletype < 4) {
        // 代理商 管理员登录
        return [
          {
            label: "嵌入式 - 监控主机",
            value: 1
          },
          {
            label: "GSM 数据网关",
            value: 2
          },
          {
            label: "保温箱 CARD",
            value: 3
          },
          {
            label: "冷藏车数据采集终端",
            value: 4
          },
          {
            label: "库房综合报警器",
            value: 5
          },
          {
            label: "STL冷链箱",
            value: 6
          },
          {
            label: "新保温箱 Card",
            value: 7
          }
        ];
      } else {
        return [];
      }
    }
  },
  watch: {
    addForm: {
      handler: function(newval, oldval) {
        if (newval.devicetype == 6) {
          this.devicekindModels = [
            {
              label: "温度变送器",
              value: 1
            }
          ];
        } else {
          this.devicekindModels = [
            {
              label: "温湿度变送器",
              value: 0
            },
            {
              label: "温度变送器",
              value: 1
            }
          ];
        }
      },
      deep: true
    },
    editForm: {
      handler: function(newval, oldval) {
        if (newval.devicetype == 6) {
          this.devicekindModels = [
            {
              label: "温度变送器",
              value: 1
            }
          ];
        } else {
          this.devicekindModels = [
            {
              label: "温湿度变送器",
              value: 0
            },
            {
              label: "温度变送器",
              value: 1
            }
          ];
        }
      },
      deep: true
    }
  },
  methods: {
    formatDeviceType: (row, column) => {
      switch (row.devicetype) {
        case 1:
          return "嵌入式 - 监控主机";
        case 2:
          return "GSM 数据网关";
        case 3:
          return "保温箱 CARD";
        case 4:
          return "冷藏车数据采集终端";
        case 5:
          return "库房综合报警器";
        case 6:
          return "STL冷链箱";
        case 7:
          return "新保温箱 Card";
        default:
          return "未知";
      }
    },
    formatDeviceKind: (row, column) => {
      switch (row.devicekind) {
        case 0:
          return "温湿度变送器";
        case 1:
          return "温度变送器";
        case 2:
          // 专门针对非温湿度设备
          return "";
        default:
          return "未知";
      }
    },
    formatCreatedAt(row, column) {
      return this.$moment(row.createdat).format("YYYY年MM月DD日");
    },
    formatExpiredAt(row, column) {
      return this.$moment(row.expiredat).format("YYYY年MM月DD日");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDevices();
    },
    toggleEditChange(val) {
      this.editForm.customerid = val;
    },
    toggleAddChange(val) {
      this.addForm.customerid = val;
    },
    //获取设备列表
    getDevices() {
      this.listLoading = true;
      NProgress.start();

      this.axios
        .post("/user/getdevices", {
          page: this.page,
          name: this.filters.name,
          customerid: this.customerid
        })
        .then(reply => {
          this.listLoading = false;
          NProgress.done();
          if (reply.data.code === 0) {
            this.total = reply.data.values.count;
            this.deviceslist = reply.data.values.devices.map(dev => {
              dev.day = this.$moment()
                .twix(dev.expiredat)
                .count("days");
              return dev;
            });
          } else {
            this.$message({
              message: `查询出错，错误码${reply.data.code},请联系管理员`,
              type: "warning"
            });
          }
        });
    },
    //删除
    handleDel: function(index, row) {
      this.$confirm("确认删除该设备吗?", "提示", {
        type: "warning"
      }).then(() => {
        this.listLoading = true;
        NProgress.start();
        this.axios
          .post("/user/deletedevice", {
            deviceid: row.deviceid,
            devicetype: row.devicetype,
            devicecode: row.devicecode
          })
          .then(res => {
            this.listLoading = false;
            NProgress.done();
            if (res.data.code === 0) {
              this.$notify({
                title: "成功",
                message: "删除成功",
                type: "success"
              });
              this.getDevices();
            } else if (res.data.code === 102) {
              this.$message({
                message: "您的权限不足",
                type: "warning"
              });
            } else {
              console.log(JSON.stringify(res.data.error));
              this.$message({
                message: `无法删除该设备，请检查是否存在关联传感器或库房。`,
                type: "warning"
              });
            }
          });
      });
    },
    //显示编辑界面
    handleEdit: function(index, row) {
      this.editFormVisible = true;
      this.editForm = Object.assign(this.editForm, row);
      this.editForm.expiredat = new Date(row.expiredat);
      this.editeddevicecode = row.devicecode;
      if (row.sim == null) {
        this.editForm.sim = "";
      }
      if (row.sim != null && row.sim != "") {
        this.axios.post("/user/getsiminfo", { ccid: row.sim }).then(res => {
          if (res.data.code == 0) {
            this.simContent = `卡号:${res.data.values.msisdn} 到期月份:${
              res.data.values.endtime
            } 当月剩余流量:${Number.parseInt(res.data.values.leftflow) /
              1000}M`;
          } else if (res.data.code == 5) {
            this.simContent = "未激活";
          } else {
            this.simContent = "查询错误";
          }
        });
      }
    },
    //显示新增界面
    handleAdd: function() {
      this.addFormVisible = true;
      this.addForm = {
        devicecode: "",
        devicename: "",
        sn: "",
        sim: "",
        devicetype: null,
        devicekind: null,
        customerid: this.customerid,
        expiredat: new Date()
      };
    },
    //编辑
    editSubmit: function() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            NProgress.start();
            let params = {};
            Object.assign(params, this.editForm, {
              editeddevicecode: this.editeddevicecode
            });

            params.expiredat = new Date(params.expiredat).format(
              "yyyy-mm-dd HH:MM:ss"
            );
            this.axios.post("/user/editdevice", params).then(res => {
              this.editLoading = false;
              NProgress.done();
              if (res.data.code === 0) {
                this.$notify({
                  title: "成功",
                  message: "编辑成功",
                  type: "success"
                });
                this.$refs["editForm"].resetFields();
                this.editFormVisible = false;
                this.getDevices();
              } else if (res.data.code === 102) {
                this.$message({
                  message: "您的权限不足",
                  type: "warning"
                });
              } else if (res.data.code === 114) {
                this.$message({
                  message: "设备号重复，请确认",
                  type: "warning"
                });
              } else {
                this.$message({
                  message: `保存失败，错误码${res.data.code},请联系管理员`,
                  type: "warning"
                });
              }
            });
          });
        }
      });
    },
    //新增
    addSubmit: function() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.addLoading = true;
            NProgress.start();
            let params = {};
            Object.assign(params, this.addForm);
            params.expiredat = new Date(params.expiredat).format(
              "yyyy-mm-dd HH:MM:ss"
            );
            this.axios.post("/user/adddevice", params).then(res => {
              this.addLoading = false;
              NProgress.done();
              if (res.data.code === 0) {
                this.$refs["addForm"].resetFields();
                this.addFormVisible = false;
                this.getDevices();
              } else if (res.data.code === 102) {
                this.$message({
                  message: "您的权限不足",
                  type: "warning"
                });
              } else if (res.data.code === 114) {
                this.$message({
                  message: "设备号重复，请确认",
                  type: "warning"
                });
              } else {
                this.$message({
                  message: `保存出错，错误码${res.data.code},请联系管理员`,
                  type: "warning"
                });
              }
            });
          });
        }
      });
    },
    addFormDeviceTypeSelected(item) {
      if (item == 5) {
        // 库房综合报警器，没有设备类型可选
        this.addForm.isWarehouseAlarm = true;
      } else {
        this.addForm.isWarehouseAlarm = false;
      }
    }
  },
  mounted() {
    this.getDevices();
  }
};
</script>